import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/colebemis.com/colebemis.com/src/templates/note-template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Macbook Pro (16-inch, 2019)`}</li>
      <li parentName="ul">{`iPad (6th generation)`}</li>
      <li parentName="ul">{`iPhone 6`}</li>
      <li parentName="ul">{`AirPods Pro`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.google.com/chrome/"
        }}>{`Chrome`}</a><ul parentName="li">
          <li parentName="ul">{`Extensions:`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://chrome.google.com/webstore/detail/stayfocusd/laankejkbhbdhmipfmgcngdelahlfoji?hl=en"
                }}>{`StayFocusd`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://1password.com/downloads/mac/#browsers"
                }}>{`1Password`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.grammarly.com/"
                }}>{`Grammarly`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://privacy.com/"
                }}>{`Pay by Privacy.com`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://usetabio.com"
                }}>{`Tabio`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://chrome.google.com/webstore/detail/lucid/achogfadpkcepkepcpegehpiiioihmik?hl=en"
                }}>{`Lucid`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://octolinker.now.sh/"
                }}>{`Octolinker`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://chrome.google.com/webstore/detail/honey/bmnlcjabgnpnenekpadlanbbkooimhnj"
                }}>{`Honey`}</a></li>
            </ul></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`VS Code`}</a><ul parentName="li">
          <li parentName="ul">{`Font family: `}<a parentName="li" {...{
              "href": "https://www.jetbrains.com/lp/mono/"
            }}>{`JetBrains Mono`}</a></li>
          <li parentName="ul">{`Dark color theme: `}<a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=GitHub.github-vscode-theme"
            }}>{`GitHub Dark`}</a></li>
          <li parentName="ul">{`Light color theme: `}<a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=GitHub.github-vscode-theme"
            }}>{`GitHub Light`}</a></li>
          <li parentName="ul">{`Icon theme: `}<a parentName="li" {...{
              "href": "https://marketplace.visualstudio.com/items?itemName=LaurentTreguier.vscode-simple-icons"
            }}>{`Simple icons`}</a></li>
          <li parentName="ul">{`Extensions:`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=GitHub.vscode-pull-request-github"
                }}>{`GitHub Pull Requests and Issues`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=eamodio.gitlens"
                }}>{`GitLens`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=wix.vscode-import-cost"
                }}>{`Import Cost`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=silvenon.mdx"
                }}>{`MDX`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=dbaeumer.vscode-eslint"
                }}>{`ESLint`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=esbenp.prettier-vscode"
                }}>{`Prettier`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=vscodevim.vim"
                }}>{`Vim`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://marketplace.visualstudio.com/items?itemName=jpoissonnier.vscode-styled-components"
                }}>{`vscode-styled-components`}</a></li>
            </ul></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://todoist.com/"
        }}>{`Todoist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://roamresearch.com/"
        }}>{`Roam`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.alfredapp.com/"
        }}>{`Alfred`}</a><ul parentName="li">
          <li parentName="ul">{`Workflows:`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://github.com/sindresorhus/alfred-dark-mode"
                }}>{`Dark Mode`}</a></li>
            </ul></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://apps.apple.com/us/app/annotate-capture-and-share/id918207447?mt=12"
        }}>{`Annotate`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getkap.co/"
        }}>{`Kap`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://excalidraw.com/"
        }}>{`Excalidraw`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.spotify.com/"
        }}>{`Spotify`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/"
        }}>{`Figma`}</a><ul parentName="li">
          <li parentName="ul">{`Plugins:`}<ul parentName="li">
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/757836922707087381/Scripter"
                }}>{`Scripter`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/772952119002135124/Minimap"
                }}>{`Minimap`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/742073255743594050/Figma-Chat"
                }}>{`Figma Chat`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/744047966581015514/Feather-Icons"
                }}>{`Feather Icons`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/731627216655469013/Content-Reel"
                }}>{`Content Reel`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/731260060173130163/Super-Tidy"
                }}>{`Super Tidy`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/734492262527930956/Time-Machine"
                }}>{`Time Machine`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.figma.com/community/plugin/886688414738743606/Hosted-Export"
                }}>{`Hosted Export`}</a></li>
            </ul></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://insomnia.rest/"
        }}>{`Insomnia`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://slack.com/"
        }}>{`Slack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://zoom.us"
        }}>{`Zoom`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://1password.com/"
        }}>{`1Password`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.iterm2.com/"
        }}>{`iTerm`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brew.sh/"
        }}>{`Homebrew`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://magnet.crowdcafe.com/"
        }}>{`Magnet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://1.1.1.1/"
        }}>{`1.1.1.1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://duckduckgo.com/"
        }}>{`DuckDuckGo`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      